"use client";

import { type Variants, motion } from "framer-motion";
import { usePathname } from "next/navigation";
import type { ReactNode } from "react";
import { useMemo, useState } from "react";
import { FiChevronDown } from "react-icons/fi";

import type { MainMenuSchema } from "@/config/site";
import { cn } from "@/lib/utils";

interface DesktopDropdownProps extends MainMenuSchema {
  children: ReactNode;
}

export function DesktopDropdown(props: DesktopDropdownProps) {
  const pathname = usePathname();
  const [showing, setShowing] = useState(false);
  const variants = {
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.25,
        ease: "easeInOut",
      },
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.25,
        ease: "easeInOut",
      },
    },
  } satisfies Variants;

  const isChildPathMatchWithPathname = useMemo(() => {
    return props.child_path?.some((item) => item.path === pathname);
  }, [pathname, props.child_path]);

  return (
    <li
      className="relative flex items-center text-center align-middle"
      onMouseEnter={() => setShowing(true)}
      onMouseLeave={() => setShowing(false)}
    >
      <div className="flex cursor-pointer justify-center align-middle">
        <span
          className={cn(
            "flex h-16 items-center justify-center text-xl tracking-wider hover:animate-text-glow-white xl:text-2xl",
            {
              "animate-text-glow-white": isChildPathMatchWithPathname,
            },
          )}
        >
          {props.name}{" "}
          <FiChevronDown
            size={24}
            className="relative top-1 my-auto ml-2 text-base"
          />
        </span>
      </div>
      <motion.div
        initial="hidden"
        animate={showing ? "visible" : "hidden"}
        variants={variants}
        className={cn(
          "absolute left-1/2 top-full z-50 block -translate-x-1/2 overflow-hidden",
        )}
      >
        <div className="mt-1 whitespace-nowrap rounded-sm bg-black/80 pt-2 font-jost">
          <ul>{props.children}</ul>
        </div>
      </motion.div>
    </li>
  );
}

import type { Metadata, Viewport } from "next";
import type { ReactNode } from "react";

export type MainMenuSchema = {
  name: string | ReactNode;
  path?: string;
  child_path?: MainMenuSchema[];
  external?: boolean;
};

export type SidebarMenuSchema = {
  display: string;
  value: string;
  disabled?: boolean;
};

export type OnBoardURLSchema = {
  title: string;
  href: string;
  menu?: OnBoardURLSchema[];
};

export type SiteConfig = {
  url: string;
  title: string;
  description: string;
  twitterHandle: string;
  discordServer: string;
  mainMenuSchema: MainMenuSchema[];
  sidebarMenuSchema: SidebarMenuSchema[];
};

export const marketplaces = {
  sniper: "https://www.sniper.xyz/collection/galactic_geckos",
  tensor: "https://www.tensor.trade/trade/galactic_geckos",
  magicEden: "https://magiceden.io/creators/galactic_gecko_space_garage",
};

export const siteConfig: SiteConfig = {
  url: "https://galacticgeckos.app",
  title: "Galactic Geckos",
  description:
    "Galactic Gecko Space Garage is an NFT Social Adventure Club featuring 10,000 warrior turned space racing geckos.",
  twitterHandle: "GalacticGeckoSG",
  discordServer: "TheGeckoDAO",
  mainMenuSchema: [
    {
      name: "Guide",
      child_path: [
        {
          name: "Onboarding",
          path: "/guide/blockchain-basics",
        },
        {
          name: "Galactic Guide",
          path: "/member/galactic-guide",
        },
      ],
    },
    {
      name: "My Garage",
      path: "/member/dashboard",
    },
    {
      name: "Marketplaces",
      child_path: [
        {
          name: "Tensor",
          path: marketplaces.tensor,
          external: true,
        },
        {
          name: "Magic Eden",
          path: marketplaces.magicEden,
          external: true,
        },
        {
          name: "Sniper.xyz",
          path: marketplaces.sniper,
          external: true,
        },
      ],
    },
    {
      name: "Tools",
      child_path: [
        {
          name: "Gecko Rarity",
          path: "/gecko-rarity",
        },
        {
          name: "Concordian Rarity",
          path: "/concordian-rarity",
        },
        {
          name: "Verified Holders List",
          path: "/verified-holders-list",
        },
        {
          name: "Airdrop Reward List",
          path: "/airdrop-reward-list",
        },
        {
          name: "Pimp My Geck",
          path: "https://www.illogicalendeavors.com/gecko/pimp/",
          external: true,
        },
      ],
    },
    {
      name: "Merch",
      path: "https://galacticgeckomerch.myshopify.com/",
      external: true,
    },
  ],
  sidebarMenuSchema: [
    {
      display: "Dashboard",
      value: "/member/dashboard",
    },
    {
      display: "Staking",
      value: "/member/staking",
    },
    {
      display: "CRED Leaderboard",
      value: "/member/cred-leaderboard",
    },
    {
      display: "Galactic Guide",
      value: "/member/galactic-guide",
    },
    {
      display: "Animation Station",
      value: "/member/coming-soon/animation-station",
    },
    {
      display: "G8+",
      value: "/member/g8-plus",
    },
  ],
};

export const sharedMetadata: Metadata = {
  metadataBase: new URL(siteConfig.url),
  alternates: {
    canonical: "/",
  },
  title: siteConfig.title,
  description: siteConfig.description,
  openGraph: {
    title: siteConfig.title,
    description: siteConfig.description,
  },
  twitter: {
    card: "summary_large_image",
    site: `@${siteConfig.twitterHandle}`,
    creator: `@${siteConfig.twitterHandle}`,
  },
};

export const sharedViewport: Viewport = {
  width: "device-width",
  initialScale: 1,
  maximumScale: 1,
  themeColor: "#000",
};

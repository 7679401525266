"use client";

import { motion } from "framer-motion";
import { usePathname } from "next/navigation";
import { useMemo, useState } from "react";
import { FiChevronDown } from "react-icons/fi";

import type { MainMenuSchema } from "@/config/site";
import { cn } from "@/lib/utils";

import MobileDropDownItem from "./mobile-dropdown-item";

type MobileDropdownProps = MainMenuSchema;

export function MobileDropDown(props: MobileDropdownProps) {
  const pathname = usePathname();
  const [selected, setSelected] = useState(false);
  const variants = {
    initial: { height: "0" },
    animate: { height: "auto", transition: { duration: 0.1 } },
  };

  const isChildPathMatchWithPathname = useMemo(() => {
    return props.child_path?.some((item) => item.path === pathname);
  }, [pathname, props.child_path]);

  return (
    <li
      onClick={() => setSelected(!selected)}
      onKeyDown={() => setSelected(!selected)}
    >
      <div className="relative flex flex-row">
        <span
          className={cn(
            "w-full px-4 py-2 pr-9 text-base hover:animate-text-glow-white",
            {
              "animate-text-glow-white": isChildPathMatchWithPathname,
            },
          )}
        >
          {props.name}
        </span>
        <FiChevronDown
          size={24}
          className="absolute right-2 top-[50%] -mt-3 transition-transform"
          style={{ transform: `rotate(${selected ? "180deg" : "0deg"})` }}
        />
      </div>
      <motion.div
        initial="initial"
        animate={selected ? "animate" : "initial"}
        variants={variants}
        className="flex w-full overflow-hidden"
      >
        <ul className="w-full">
          {props.child_path?.map((dropDownPageData) => {
            return (
              <MobileDropDownItem
                key={dropDownPageData.path}
                path={dropDownPageData.path}
                name={dropDownPageData.name}
                external={dropDownPageData.external}
              />
            );
          })}
        </ul>
      </motion.div>
    </li>
  );
}

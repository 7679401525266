"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";

import type { MainMenuSchema } from "@/config/site";
import { cn } from "@/lib/utils";

type DesktopDropdownItemProps = MainMenuSchema;

export function DesktopDropdownItem(props: DesktopDropdownItemProps) {
  const pathname = usePathname();

  return (
    <li className="flex items-center text-left align-middle font-vt323">
      <Link
        href={props.path || ""}
        target={props.external ? "_blank" : "_self"}
        className={cn(
          "mb-2 w-full px-3 font-jost text-base tracking-wider hover:animate-text-glow-white",
          {
            "animate-text-glow-white": pathname === props.path,
          },
        )}
      >
        {props.name}
      </Link>
    </li>
  );
}

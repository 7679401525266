import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Jost\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"700\"],\"display\":\"block\"}],\"variableName\":\"jost\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"VT323\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"400\",\"display\":\"block\"}],\"variableName\":\"vt323\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Kalam\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"400\",\"display\":\"block\"}],\"variableName\":\"kalam\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/zombie.otf\",\"weight\":\"700\",\"style\":\"bold\"}],\"variable\":\"--font-zombie\",\"display\":\"block\"}],\"variableName\":\"zombie\"}");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/app/src/assets/bg-planet-top.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/logo-text-ggsg.png");
;
import(/* webpackMode: "eager", webpackExports: ["DesktopDropdownItem"] */ "/app/src/components/common/navbar/desktop-dropdown-item.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DesktopDropdown"] */ "/app/src/components/common/navbar/desktop-dropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DesktopMenuItem"] */ "/app/src/components/common/navbar/desktop-menu-item.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileMenu"] */ "/app/src/components/common/navbar/mobile-menu.tsx");

"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";

import type { MainMenuSchema } from "@/config/site";
import { cn } from "@/lib/utils";

type DesktopDropdownItemProps = MainMenuSchema;

export function DesktopMenuItem(props: DesktopDropdownItemProps) {
  const pathname = usePathname();

  return (
    <li className="flex cursor-pointer items-stretch whitespace-nowrap text-center font-zombie">
      <Link
        href={props.path || ""}
        target={props.external ? "_blank" : "_self"}
        className={cn(
          "flex h-16 items-center justify-center text-xl tracking-wider hover:animate-text-glow-white xl:text-2xl",
          {
            "animate-text-glow-white": pathname === props.path,
          },
        )}
      >
        {props.name}
      </Link>
    </li>
  );
}

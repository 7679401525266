"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";

import type { MainMenuSchema } from "@/config/site";
import { cn } from "@/lib/utils";

type MobileMenuItemProps = MainMenuSchema;

export function MobileMenuItem(props: MobileMenuItemProps) {
  const pathname = usePathname();

  return (
    <li className="flex w-full">
      <Link
        href={props.path || ""}
        target={props.external ? "_blank" : "_self"}
        className={cn("w-full px-4 py-2 hover:animate-text-glow-white", {
          "animate-text-glow-white": pathname === props.path,
        })}
      >
        {props.name}
      </Link>
    </li>
  );
}

"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";

import type { MainMenuSchema } from "@/config/site";
import { cn } from "@/lib/utils";

type MobileDropdownItemProps = MainMenuSchema;

export default function MobileDropDownItem(props: MobileDropdownItemProps) {
  const pathname = usePathname();

  return (
    <li className="flex w-full">
      <Link
        href={props.path || ""}
        target={props.external ? "_blank" : "_self"}
        className={cn(
          "w-full py-2 pl-5 pr-4 text-[#CBCBCB] hover:animate-text-glow-white",
          {
            "animate-text-glow-white": pathname === props.path,
          },
        )}
      >
        {props.name}
      </Link>
    </li>
  );
}

"use client";

import { motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import { BsDiscord, BsTwitter } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";

import logoTextGGSG from "@/assets/logo-text-ggsg.png";
import { siteConfig } from "@/config/site";
import { cn } from "@/lib/utils";

import { MobileDropDown } from "./mobile-dropdown";
import { MobileMenuItem } from "./mobile-menu-item";

export function MobileMenu() {
  const [menuVisible, setMenuVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const hamburger = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const clickOutside = (e: MouseEvent) => {
      if (
        menuVisible &&
        ref.current &&
        !ref.current.contains(e.target as HTMLElement) &&
        !hamburger.current?.contains(e.target as HTMLElement)
      ) {
        setMenuVisible(false);
      }
    };
    document.addEventListener("mousedown", clickOutside);
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [menuVisible]);

  return (
    <div className="relative z-50 size-full select-none md:hidden">
      <div className="h-[44px] bg-black/[85%]">
        <div className="flex flex-row">
          <span ref={hamburger}>
            <button
              type="button"
              className="flex h-[44px] items-center justify-center px-4"
              onClick={() => setMenuVisible(!menuVisible)}
              aria-label="Toggle Menu"
            >
              <GiHamburgerMenu size={24} />
            </button>
          </span>
          <Link href="/" className="relative flex items-center">
            <Image
              alt="GGSG"
              src={logoTextGGSG}
              quality={75}
              className="h-auto max-w-full"
              width={58}
              height={22}
              sizes="58px"
              priority
            />
          </Link>
        </div>
      </div>

      <div
        ref={ref}
        className={cn(
          "container absolute top-[44px] z-50 flex h-screen w-[220px] flex-col bg-black/[85%] transition-all duration-100 ease-in-out",
          {
            "left-0": menuVisible,
            "left-[-150%]": !menuVisible,
          },
        )}
      >
        <div className="flex h-[calc(100vh-44px)] w-full flex-col items-stretch">
          <ul className="w-full flex-auto cursor-pointer list-none justify-center font-jost">
            {siteConfig.mainMenuSchema.map((pageData) => {
              return !pageData.child_path ? (
                <MobileMenuItem key={pageData.name as string} {...pageData} />
              ) : (
                <MobileDropDown key={pageData.name as string} {...pageData} />
              );
            })}
          </ul>
          <div className="flex flex-1 grow-0 flex-row items-center justify-center py-4 text-[0px]">
            <a
              href={`https://twitter.com/${siteConfig.twitterHandle}`}
              target="_blank"
              rel="noreferrer"
              className="mx-3 -mt-1"
              aria-label="Go to our Twitter"
            >
              <motion.div
                whileHover={{
                  scale: 1.1,
                  transition: {
                    type: "spring",
                    stiffness: 100,
                    duration: 0.8,
                  },
                }}
                whileTap={{
                  scale: 0.9,
                  transition: {
                    type: "spring",
                    stiffness: 100,
                    duration: 0.8,
                  },
                }}
                className="mx-auto flex w-4/5 md:w-full"
              >
                <BsTwitter size={40} className="mx-auto fill-twitter" />
              </motion.div>
            </a>
            <a
              href={`https://discord.gg/${siteConfig.discordServer}`}
              target="_blank"
              rel="noreferrer"
              className="mx-3"
              aria-label="Go to our Discord"
            >
              <motion.div
                whileHover={{
                  scale: 1.1,
                  transition: {
                    type: "spring",
                    stiffness: 100,
                    duration: 0.8,
                  },
                }}
                whileTap={{
                  scale: 0.9,
                  transition: {
                    type: "spring",
                    stiffness: 100,
                    duration: 0.8,
                  },
                }}
                className="mx-auto flex w-4/5 md:w-full"
              >
                <BsDiscord size={40} className="mx-auto fill-discord" />
              </motion.div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
